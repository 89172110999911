<template>
  <div class="evo-privacy-policy-wrapper">
    <div class="evo-mb-20">
      <v-breadcrumbs :items="breadcrumbs" class="evo-breadcrumbs">
        <template #divider>
          <v-icon>$vuetify.icons.mdiChevronRight</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <EvoMarkdown :content="md" />

    <div class="evo-px-hline evo-mt-28 evo-mb-28"></div>

    <div class="evo-last-update-text evo-text-20 evo-mb-12">
      {{ $t('other_policies') }}
    </div>

    <div class="evo-privacy-items-wrapper">
      <v-row class="evo-policy-items" align="start" justify="space-between">
        <v-col cols="12" sm="12" md="4" v-for="(item, index) in items" :key="index">
          <router-link :to="item.link" target="_self" class="router-link">
            <div class="evo-item-wrapper" data-aos="fade-up">
              <div class="evo-item">
                <div class="evo-item-title evo-text-13">{{ $t(item.desc) }}</div>
                <div class="evo-item-desc evo-text-10">{{ $t(item.desc) }}</div>
              </div>
            </div>
          </router-link>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { legalDetailMixin } from '@/mixins/legal.js';
export default {
  name: 'EvoPolicyDetailSection',
  mixins: [legalDetailMixin]
};
</script>
<style lang="scss" scoped>
.evo-privacy-policy-wrapper {
  margin-top: 36px;

  .evo-px-hline {
    height: 2px;
    background: #dbdbdb;
  }

  .evo-last-update-text {
    color: #233d63;
    text-align: center;
  }

  .evo-items {
    width: 70%;
    margin: 0 auto;
  }

  ::v-deep(.evo-breadcrumbs) {
    padding-left: 0;
    padding-top: 0;

    .v-breadcrumbs__item {
      color: #222a41;
      font-size: 15px;
      font-family: 'OpenSans-Regular';
    }
  }

  .evo-privacy-items-wrapper {
    background: #fafafa;
    position: relative;
    width: 70%;
    margin: 0 auto;
    .evo-policy-items {
      .evo-item-wrapper {
        box-shadow: 15px 15px 34px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        background: #ffffff;
        cursor: pointer;
      }

      .evo-item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background-image: url('~@/assets/legal/legal-bg.png');
        background-size: 100% 66%;
        background-repeat: no-repeat;
        padding: 12px;
        height: 180px;

        & > div {
          position: relative;
        }

        .evo-item-title {
          color: #ffffff;
          margin-top: 32px;
          text-align: center;
        }

        .evo-item-desc {
          margin-top: 20px;
          color: #1a47b0;
        }
      }
    }
  }
}
</style>
