<template>
  <div>
    <template v-if="$vuetify.breakpoint.mobile">
      <EvoMPolicyDetailSection />
    </template>
    <template v-else>
      <EvoPolicyDetailSection />
    </template>
  </div>
</template>
<script>
import EvoPolicyDetailSection from '@/components/legal_sections/Detail.vue';
import EvoMPolicyDetailSection from '@/mobile/components/legal_sections/MDetail.vue';
export default {
  name: 'EvoPolicyDetailView',
  components: {
    EvoPolicyDetailSection,
    EvoMPolicyDetailSection
  }
};
</script>
